.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url(../assets/background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.indicatorLeft {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  text-align: center;
  padding: 12px;
}

.indicatorRight {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  text-align: center;
  padding: 12px;
}

.indicatorText {
  color: #81614e;
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 12px;
}

.indicatorImage {
  width: 100%;
}

.card {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  transition: 0.4s;
}

.cardHide {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  transition: 0.4s;
}

.cardLeft {
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.cardRight {
  left: 100%;
  opacity: 0;
  pointer-events: none;
}

.topContainer {
  position: absolute;
  top: 10%;
  right: 8%;
  display: flex;
  justify-content: center;
}

.countdownContainer {
  color: #81614e;
  padding: 12px;
  text-align: center;
}

.countdownText {
  font-size: 1em;
  font-weight: bold;
}

.countdownNumber {
  font-size: 1.8em;
  font-weight: bold;
}

.scoreContainer {
  color: #81614e;
  padding: 12px;
  text-align: center;
}

.scoreText {
  font-size: 1em;
  font-weight: bold;
}

.scoreNumber {
  font-size: 1.8em;
  font-weight: bold;
}
