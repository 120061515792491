.container {
  background: var(--teal);
  padding-top: 2rem;
}

.title {
  text-align: center;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 2rem;
}

.subtitle {
  text-align: center;
  color: white;
  font-size: 1.2rem;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.question {
  width: 400px;
  color: white;
}

.nextButton {
  text-align: right;
  margin-top: 24px;
}

.page {
  text-align: center;
  color: white;
}

.note {
  color: white;
  font-size: 0.8rem;
  margin-top: 6px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  padding-top: 12px;
}

.content {
  padding: 12px 40px;
}

.input {
  width: 100%;
  font-size: 1.2rem;
  padding: 12px;
  border: 4px solid var(--teal);
  border-radius: 12px;
}
