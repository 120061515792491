.sceneContainer {
  height: 100vh;
  overflow: hidden;
}

.sceneTop {
  height: 10%;
}

.sceneCenter {
  height: 65%;
  display: flex;
}

.textCenter {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding: 2rem;
}

.sceneBottom {
  height: 25%;
  padding: 0px 2rem;
  position: relative;
}
