.characterContainer {
  /* width: fit-content; */
  min-width: 30%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.characterImage {
  height: 60vh;
  margin-left: 2rem;
  margin-right: 2rem;
  animation: showCharacter 0.8s;
}

@keyframes showCharacter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
