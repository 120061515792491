.container {
  position: relative;
  margin: 0px 1rem;
}

.icon {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translate(-50%, -50%);
  width: 2.5rem;
  height: 2.5rem;
}

.box {
  background-color: var(--teal-blue);
  /* width: 120px; */
  text-align: center;
  padding: 0.5rem 3rem 0.5rem 4rem;
  color: var(--yellow);
  font-weight: bold;
  border-radius: 24px;
}
