.videoContainer {
  position: relative;
  /* height: 90%; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  transform: scale(0);
  animation-name: show;
  animation-delay: 0.8s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.videoBox {
  /* height: 100%; */
  width: 100%;
}

.videoControls {
  position: absolute;
  z-index: 2;
  bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
