.Petunjuk {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;
  opacity: 1;
  cursor: auto;
}

.PetunjukTitle {
  font-size: 40px;
}

.PetunjukList {
  font-size: 24px;
}

.PetunjukButton {
  background-color: #0432ff;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 12px;
}

.PetunjukButton:hover {
  background-color: #0432ffe5;
}
