.inputContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBox {
  width: 80%;
  padding: 1rem;
  border: 4px solid var(--teal);
  border-radius: 12px;
}
