.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/images/background.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  cursor: crosshair;
}
