:root {
  --teal: #008c89;
  --teal-blue: #064f66;
  --green: #a4d9cc;
  --green-white: #daf1e9;
  --pantone: #b7cad3;
  --red: #e54941;
  --pink: #f3bdb4;
  --yellow: #ffca7d;
  --white: #ffffff;
  --black: #000000;
}

* {
  font-family: "Nunito Sans", sans-serif;
}

@media only screen and (max-width: 1280px) {
  * {
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
  }
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.Top {
  position: fixed;
  width: 98%;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  z-index: 2;
}
