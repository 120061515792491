.container {
  background: linear-gradient(
      rgba(218, 241, 233, 0.75),
      rgba(218, 241, 233, 0.75)
    ),
    url(../assets/background/gerbangdesa.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.logo {
  width: 20rem;
}

.title {
  font-size: 3rem;
  color: var(--teal);
  font-weight: bold;
  /* margin: 20px; */
}

.subtitle {
  text-align: center;
  color: var(--teal);
  font-weight: bold;
  /* margin-bottom: 32px; */
}

.button {
  background: var(--teal);
  color: var(--white);
  border: 2px solid var(--green-white);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 3rem;
  width: 10rem;
  height: 5rem;
  /* margin: 1rem; */
  padding-left: 2rem;
  padding-right: 2rem;
}

.buttonRemedial {
  background: var(--yellow);
  color: var(--teal);
  border: 2px solid var(--green-white);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 3rem;
  width: 10rem;
  height: 5rem;
  /* margin: 1rem; */
  padding-left: 2rem;
  padding-right: 2rem;
}

.buttonContainer {
  text-align: center;
}

.miniText {
  font-size: 0.699rem;
}

