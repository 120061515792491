.optionContainer {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scale(0);
  animation-name: show;
  animation-delay: 0.8s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  /* align-items: center; */
  /* display: grid;
  grid-template-columns: 50% 50%; */
}

.optionContainerGrid {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: 50% 50%;
  transform: scale(0);
  animation-name: show;
  animation-delay: 0.8s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.optionButton {
  /* width: 800px; */
  background: var(--teal);
  color: var(--white);
  border: 2px solid var(--green-white);
  padding: 1rem;
  border-radius: 1.2rem;
  margin: 0.1rem 1rem;
}

.optionButtonSelected {
  /* width: 800px; */
  background: var(--teal-blue);
  color: var(--white);
  border: 2px solid var(--green-white);
  padding: 1rem;
  font-weight: bold;
  border-radius: 1.2rem;
  margin: 0.1rem 1rem;
}

.optionButtonGrid {
  /* width: 800px; */
  background: var(--teal);
  color: var(--white);
  border: 2px solid var(--green-white);
  border-radius: 1.2rem;
}

.optionButtonSelectedGrid {
  /* width: 800px; */
  background: var(--teal-blue);
  color: var(--white);
  border: 2px solid var(--green-white);
  font-weight: bold;
  border-radius: 1.2rem;
}

@keyframes show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
