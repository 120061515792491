.nextButton {
  background: var(--teal-blue);
  color: var(--white);
  border: 2px solid var(--green-white);
  position: absolute;
  right: 60px;
  top: -12px;
  padding: 0.5rem 2rem;
  border-radius: 24px;
}

.buttonPrimary {
  background: var(--teal);
  color: var(--white);
  border: 2px solid var(--green-white);
  font-weight: bold;
  padding: 0.5rem 3rem;
  border-radius: 24px;
}

.buttonSecondary {
  background: var(--teal-blue);
  color: var(--white);
  border: 2px solid var(--green-white);
  font-weight: bold;
  padding: 0.5rem 3rem;
  border-radius: 24px;
}
