.Score {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
}

.ScoreText {
  padding: 12px;
  color: #f00;
  font-size: 1.6em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
