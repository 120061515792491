.inputContainer {
  background-color: #fff;
  border: 2px solid var(--teal);
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin: 8px;
}

.inputIcon {
  width: 20px;
  padding: 12px;
}

.inputBox {
  border: none;
  border-radius: 12px;
  outline: none;
  width: 200px;
  font-size: 1em;
}

.inputBoxPad {
  border: none;
  border-radius: 12px;
  outline: none;
  width: 240px;
  font-size: 1em;
}

.togglePassword {
  width: 28px;
  margin-left: 8px;
  margin-right: 8px;
}
