.container {
  background-color: var(--teal-blue);
  border: 2px solid var(--green-white);
  border-radius: 24px;
  padding: 0.1rem;
  position: relative;
}

.progressBar {
  /* background-color: var(--green-white); */
  overflow: hidden;
  width: 120px;
  height: 2rem;
  border-radius: 9999px;
}

.progressValue {
  background-color: var(--green);
  height: 2rem;
}

.value {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: var(--white);
}
