.indicator {
  position: absolute;
  bottom: 0;
  left: 50vw;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.indicator_text {
  font-size: 0.8em;
  color: white;
  font-weight: bold;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
}

.progress {
  position: relative;
  flex: 1;
  height: 20px;
  margin: 12px;
  background-color: #808080;
  border: 4px solid #ffffff;
  border-radius: 20px;
}

.progress_value {
  position: absolute;
  height: 20px;
  border-radius: 20px;
  background-color: #ff0000;
}

.progress_icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}
