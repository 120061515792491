.dropdownContainer {
  max-height: 80%;
  width: 100%;
  overflow: auto;
  margin: auto 2rem;
  background-color: var(--teal);
  color: var(--white);
  border: 2px solid var(--green-white);
  border-radius: 1rem;
  padding: 1rem;
}

.dropdown {
  margin: 0px 8px 0px 0.3rem;
}
