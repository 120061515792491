.continueButton {
  text-align: center;
  animation: show 0.5s;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
