.selectPutContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selectPutBox {
  min-height: 40%;
  width: 80%;
  background-color: var(--green-white);
  border: 2px solid var(--teal);
  border-radius: 1rem;
  padding: 0.1rem;
}

.selectPutOptionContainer {
  width: 80%;
}

.selectPutOption {
  display: inline-block;
  width: fit-content;
  background-color: var(--teal);
  color: var(--white);
  border: 2px solid var(--green-white);
  border-radius: 1rem;
  margin: 0.1rem;
  padding: 1rem;
  cursor: pointer;
}

.selectPutTitle {
  text-align: center;
}
