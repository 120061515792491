.Modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 52px;
  transition: 0.4s;
}

.ModalHide {
  z-index: -1;
  opacity: 0;
}

.ModalShow {
  z-index: 2;
  opacity: 1;
}
