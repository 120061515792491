.container {
  background: linear-gradient(
      rgba(218, 241, 233, 0.75),
      rgba(218, 241, 233, 0.75)
    ),
    url(../assets/background/gerbangdesa.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.top {
  position: absolute;
  top: 0;
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.logo {
  width: 20rem;
}

.formTitle {
  color: var(--teal);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
}

.formSubtitle {
  color: var(--teal);
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 60%;
}

.formContainer {
  margin-bottom: 2rem;
}
