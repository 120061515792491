.dialogueContainer {
  background-color: var(--teal);
  color: var(--white);
  border: 2px solid var(--green-white);
  position: relative;
  height: 90%;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dialogueText {
  width: 90%;
  text-align: center;
}

.characterName {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--teal-blue);
  border-radius: 12px 0px 12px 0px;
  border: 2px solid var(--green-white);
  border-top: 0px;
  border-left: 0px;
  padding: 0.3rem 1rem;
  font-weight: bold;
}
