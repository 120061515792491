.backgroundImage {
  position: fixed;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  animation: showBackground 0.8s;
}

.backgroundVideo {
  position: fixed;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  /* min-width: 100%;
  min-height: 100%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@keyframes showBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
