.container {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url(../assets/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.playerScore {
  position: absolute;
  bottom: 12%;
  right: 60px;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  color: white;
}

.npcScore {
  position: absolute;
  top: 12%;
  left: 60px;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  color: white;
}

.playerCards {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.npcCards {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.playerDrawCard {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateY(-50%);
}

.npcDrawCard {
  position: absolute;
  top: 50%;
  right: 30%;
  transform: translateY(-50%);
}

.ronde {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2em;
  font-weight: bold;
  color: white;
}

.message {
  text-align: center;
  width: 80%;
  position: absolute;
  bottom: 16rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2em;
  font-weight: bold;
  color: white;
}
