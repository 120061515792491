.zoomContainer {
  position: relative;
  width: 50%;
  height: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--teal);
  border: 2px solid var(--green-white);
  border-radius: 24px;
}

.zoomContainerFullWidth {
  position: relative;
  width: 100%;
  height: 80%;
  margin: auto 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--teal);
  border: 2px solid var(--green-white);
  border-radius: 24px;
}

.zoomContent {
  max-height: 70%;
  max-width: 88%;
  opacity: 0;
  position: absolute;
  /* top: 12px; */
}

.zoomContentFullScreen {
  width: 86%;
  max-height: 90%;
  object-fit: contain;
  opacity: 0;
  position: absolute;
  /* top: 12px; */
}

.zoomContentActive {
  max-height: 70%;
  max-width: 88%;
  opacity: 1;
  position: absolute;
  /* top: 12px; */
}

.zoomContentActiveFullScreen {
  width: 86%;
  max-height: 90%;
  object-fit: contain;
  opacity: 1;
  position: absolute;
  /* top: 12px; */
}

.zoomHandler {
  position: absolute;
  bottom: 1em;
  width: 2.5em;
  height: 2.5em;
}

.zoomPrev {
  position: absolute;
  left: 0.4em;
  width: 2.8em;
  height: 2.8em;
}

.zoomNext {
  position: absolute;
  right: 0.4em;
  width: 2.8em;
  height: 2.8em;
}
