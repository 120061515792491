.background {
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: repeat-x;
  background-size: cover;
  background-attachment: scroll;
  width: 100vw;
  height: 100vh;
  transition: 0.2s background-position linear;
}
