.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background-color: var(--teal);
  padding: 1rem;
  border-radius: 1rem;
}

.star {
  width: 2.4rem;
  height: 2.4rem;
  margin: 0.3rem;
}
